.split-btn-vertical {
    height: inherit;
    .split-btn {
        margin-left: -14px;
        display: flex;
        width: initial;
        height: 100%;
        padding: 0.75rem 0;
    }
    .expanded {
        margin-left: -0.875rem;
    }
    .collapsed {
        margin-left: 0.125rem;
    }
    .center {
        align-items: center;
    }
}
.split-btn-horizontal {
    width: inherit;
    .split-btn {
        display: flex;
        width: 100%;
        height: initial;
        padding: 0 1rem;
    }
    .expanded {
        margin-top: -0.875rem;
    }
    .collapsed {
        margin-top: 0.125rem;
    }
}

.split-btn-vertical,
.split-btn-horizontal {
    .center {
        align-items: center;
    }

    .start {
        align-items: flex-start;
    }

    .end {
        align-items: flex-end;
    }
}
