.Search {
    height: 100%;
    display: flex;
    flex-flow: column;

    .top {
        height: 100%;
        min-width: 10em; /* min-width helps correctly size the datatable*/
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 0;
        overflow: hidden;
        margin: -0.2rem -0.2rem 0 -0.2rem;
        padding: 0.2rem 0.2rem 0 0.2rem;
        gap: 0.75rem;
    }

    .search-tree-dropdown {
        position: absolute;
        padding: 0;
        z-index: 9090; /* over 'onboarding' modal*/

        .slds-tree_container {
            max-width: initial;
        }
        .search-for-text {
            max-width: 310px;
            mark {
                margin-left: 10px;
            }
        }
    }

    .search-button {
        min-width: 7em;
    }

    
    .custom-placeholder{
        /* style from salesforce ::placeholder*/
        color:#747474;
        font-weight:400;
        opacity:1;
        /* end style from salesforce */
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
    }

    .content-editable {
        white-space: pre-line;
        max-height: 200px;
        overflow-y: auto;

        span {
            line-height: initial;
            background-color: #efefef;
            margin-inline: 0.125rem;
        }

        .text {
            padding-left: 20px;
        }

        .text::before {
            content: "";
            position: absolute;
            left: 2px;
            width: 14px;
            height: 14px;
            background-image: var(--bg-image);
            background-size: contain;
            background-repeat: no-repeat;
            filter: invert(47%) sepia(2%) saturate(15%) hue-rotate(180deg) brightness(97%) contrast(88%);
        }
    }

    .dropdown-trigger {
        position: initial;
    }
}
