.Explore {
    height: 100%;
    display: flex;
    flex-flow: column;

    .container-record-pill {
        height: 90px;
        width: 300px;
    }
    .record-pill-container {
        height: fit-content;

        .slds-pill_container {
            overflow-x: auto;
            align-items: center;
            width: 300px;
            height: 50px;
        }

        .custom-pill {
            background-color: #efefef;
        }
    }
}
