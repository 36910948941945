.builder-drop-box-container {
    min-height: 93px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;

    .box-name-wrapper {
        display: flex;
    }
    .box-name {
        margin-bottom: 0px;
        margin-right: 2px;
    }
    .tooltip-wrapper {
        position: relative;
        width: 170px;
    }

    .drop-zone {
        position: relative;
        min-height: 60px;
        padding: 5px;
        border: 1px dashed #ddd;
        width: auto;
        border-radius: 2px;

        .drop-zone-container {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            .icon-not-allowed {
                font-size: 18px;
            }

            .text {
                font-size: 12px;
            }

            .tag-drop-box {
                background-color: #efefef;
                cursor: grab;
                .slds-pill__label {
                    line-height: 14px;
                    max-width: 200px;
                }
            }
            .tag-drop-box-flt {
                background-color: #efefef;
                .slds-pill__label {
                    line-height: 14px;
                    max-width: 200px;
                }
            }
        }
        .drop-zone-container.disabled {
            flex-direction: column;
            align-items: center;
        }
    }
    .drop-zone.disabled {
        background-color: #f0f0f0;
        border-color: #ccc;
    }
    .drop-zone.enabled {
        border-color: #ddd;
    }

    .filter-item-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter-item-icon {
            font-size: 16px;
        }
    }
    .delete-all-box {
        position: absolute;
        top: 3px;
        right: 3px;
    }
}
