.ps-navigation-tree {
    .trees-nav {
        position: relative;

        .nav-trees-container {
            padding-bottom: 32px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: inherit;
            min-height: 100px;

            .tree-content {
                margin-bottom: 8px;
                /* Override styles for tree headers*/
                .slds-tree__group-header {
                    .slds-tree__item {
                        padding-left: initial;
                    }
                }

                /* Override styles for tree items width to fit in the parent width */
                .slds-size_1-of-1 {
                    width: 91%;
                }

                .icon-label-container{
                    display: flex;
                    align-items: flex-end;
                }

                /* Override styles for items with id ending none-item-id*/
                [id$="-none-item-id"] {
                    span {
                        color: #c9c9c9;
                    }
                }
                [id$="-none-item-id"]:focus > .slds-tree__item {
                    background: initial;
                    cursor: initial;
                    text-decoration: none;
                }
                [id$="-none-item-id"]:hover > .slds-tree__item {
                    background: initial;
                    cursor: initial;
                }
            }
        }

        .nav-trees-draggable {
            .tree-item-draggable {
                display: flex;
                align-items: flex-end;
                cursor: grab;
            }
        }
    }


    .pill-container {
        height: 46px;
    
        .slds-pill_container {
            border: initial;
            padding-inline: initial;
            overflow-y: auto;
            height: inherit;
            align-items: center;
    
            .slds-pill__icon_container {
                width: initial;
                height: initial;
                margin-right: none;
                
                .slds-icon {
                    width: 0.875rem;
                    height: 0.875rem;
                    line-height: 1;
                }
            }
        }
    
        .custom-pill {
            background-color: #efefef;
            .slds-pill__label {
                line-height: 14px;
                max-width: 100px;
            }
        }
    }
}
