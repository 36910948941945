.build-drop-box-container {
    overflow: hidden;

    .card-drop-box {
        .slds-card__header {
            display: none;
        }
        .slds-card__body {
            min-height: 100%;
            margin: initial;
            display: flex;

            .box-content {
                width: 334px;  /* Same with navigation tree */
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                height: auto;
                padding: 0.75rem 1rem;
                border: initial;
            }
        }
    }
}
