.ps-error-boundary-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .boundary-back-btn {
        all: unset;
        color: #215db0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
