.top-content {
    flex: 1;
    margin-bottom: 10px;
    border: 1px solid #0b0000;
    padding: 10px;
}
.jump-icon {
    border-radius: 50% !important;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .top-content,
    .bottom-content {
        margin-bottom: 10px;
    }
}

.build {
    height: 100%;
    display: flex;
    flex-flow: column;

}
.build-drag-item{
    position: absolute;
    top: -9999px;
    padding: 2px 4px 2px 16px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
    min-width: 200px;
}

/* Divider */
.divider {
    position: relative;
    height: 100%;
    background-color: rgba(224, 224, 224, 0.8);
    margin: 0 20px 0 10px;
    top: 0;
    bottom: 0;
    width: 2px;
    .jump-icon {
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
    }
}
